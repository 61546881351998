import React from "react";
import { graphql, Link } from "gatsby";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { LessonPost } from "../components";

export default function Template({ data }: { data: { lesson: any } }) {
  const { lesson } = data;
  const prevUrl = lesson.frontmatter.prev
    ? lesson.frontmatter.order === 0
      ? undefined
      : `/lesson/${lesson.frontmatter.parentCourse}${lesson.frontmatter.prev?.url}`
    : undefined;
  const nextUrl = lesson.frontmatter.next
    ? `/lesson/${lesson.frontmatter.parentCourse}${lesson.frontmatter.next?.url}`
    : undefined;

  return (
    <LayoutMain
      isBlogPost={false}
      frontmatter={lesson.frontmatter}
      title={lesson.frontmatter.title}
      metaTitle={lesson.frontmatter.metaTitle}
    >
      <div className="blog-post-container">
        <LessonPost lesson={lesson} />
        <div className="lesson-nav">
          {prevUrl && (
            <Link className="prev-link" to={prevUrl}>
              Previous: {lesson.frontmatter.prev.title}
            </Link>
          )}
          {nextUrl && (
            <Link className="next-link" to={nextUrl}>
              Next: {lesson.frontmatter.next.title}
            </Link>
          )}
        </div>
      </div>
    </LayoutMain>
  );
}

export const LessonBySlug = graphql`
  query ($slug: String!, $parentModule: String!, $parentCourse: String!) {
    lesson: mdx(
      frontmatter: {
        slug: { eq: $slug }
        type: { eq: "lesson" }
        parentModule: { eq: $parentModule }
        parentCourse: { eq: $parentCourse }
      }
    ) {
      body
      frontmatter {
        title
        date
        category
        categorySlug
        author
        description
        status
        bannerAlt
        parentCourse
        parentModule
        keywords
        prev {
          url
          title
        }
        next {
          url
          title
        }
        prevModule {
          title
          url
        }
        nextModule {
          title
          url
        }
        banner {
          id
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        order
        type
        slug
        metaDescription
        metaTitle
      }
    }
  }
`;
